@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap");
@import "~bootstrap/scss/bootstrap";
/* dm-sans-regular - latin */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$sheeo-teal: #0b84a8;
$sheeo-lightTeal: #80d5e3;
$sheeo-grey: #54595f;
$sheeo-lightGrey: #ccc;
$sheeo-red: #ce2025;
$sheeo-purple: #7515d9;
$sheeo-black: #000;
$sheeo-white: #fff;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background: #f2f2f2;
}

.landingContainer {
  font-family: "Poppins", sans-serif;

  .topBar {
    height: 0px;
    background: $sheeo-teal;
  }
  .logoMenuBar {
    background: #f2f2f2;
    border-bottom: solid 1px $sheeo-teal;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 40px;
    width: 100%;
    float: left;
    .logoMenuContainer {
      width: 960px;
      margin: auto;
      .logo {
        width: 200px;
        margin: auto;
        img {
          width: 200px;
        }
      }
      .menuContainer {
        float: right;
        margin-top: 0px;
        .menu {
          float: left;
          padding: 10px 25px;
          .menuText {
            a {
              color: $sheeo-black;
            }
            a:hover {
              text-decoration: none;
            }
            font-size: 14px;
            font-weight: bold;
          }
          border-bottom: solid $sheeo-white 2px;
        }
        .menu:hover {
          cursor: pointer;
          border-bottom: solid $sheeo-red 2px;
          transition: 0.2s;
        }
      }
    }
  }
  h1 {
    text-align: center;
    font-size: 60px;
    font-weight: 400;
    line-height: 70px;
    margin-bottom: 15px;
  }
  .description {
    text-align: center;
    font-size: 18px;
    width: 600px;
    margin: auto;
    line-height: 26px;
  }
  .paymentOptions {
    width: 800px;
    margin: auto;
    margin-top: 100px;
    text-align: center;
    .hidden {
      display: none;
    }
  }
  .ctaContainer {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .primary-cta {
    font-size: 18px;
    font-weight: 700;
    fill: #fff;
    color: #fff;
    background-color: $sheeo-teal;
    border-radius: 0;
    padding: 1em 3em;
    text-transform: uppercase;
    transition: 0.3s;
  }
  .loading-cta {
    font-size: 18px;
    font-weight: 700;
    fill: #fff;
    color: #fff;
    background-color: $sheeo-teal;
    border-radius: 0;
    text-transform: uppercase;
    transition: 0.3s;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .primary-cta:hover {
    text-decoration: none;
    background-color: $sheeo-lightTeal;
  }
  .price {
    font-size: 50px;
    font-weight: bold;
    .asterisk {
      font-size: 20px;
    }
  }
  .period {
    margin-top: -10px;
    margin-bottom: 20px;
  }
  .regionContainer {
    width: 750px;
    margin: auto;
    text-align: center;
    display: block;
    margin-top: 20px;
    margin-bottom: 50px;
    .description {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .regionButton {
      border: solid 2px $sheeo-red;
      border-radius: 5px;
      padding: 5px 10px;
      width: 140px;
      float: left;
      margin-right: 10px;
      color: $sheeo-red;
      font-weight: 600;
    }
    .regionButton:hover {
      cursor: pointer;
      opacity: 0.5;
    }
    .active {
      color: $sheeo-white;
      background: $sheeo-red;
    }
  }

  .eventImage {
    width: 780px;
    margin: auto;
    img {
      width: 780px;
    }
    margin-bottom: 40px;
  }

  .eventDate {
    text-align: center;
    font-size: 14px;
  }

  .eventDescription {
    width: 500px;
    margin: auto;
    margin-top: 20px;
  }

  .eventName {
    text-align: center;
    font-size: 26px;
  }
  h4 {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }
  .emailEntry {
    width: 500px;
    margin: auto;
    text-align: center;
    margin-top: 20px;
    border-top: solid 1px $sheeo-lightGrey;
    padding-top: 20px;
    margin-bottom: 150px;
    input {
      padding: 10px 20px;
      font-size: 36px;
      width: 100%;
      text-align: center;
      border-radius: 5px;
      border: solid 1px $sheeo-lightTeal;
      margin-bottom: 20px;
    }
    .valError {
      font-size: 16px;
      color: $sheeo-red;
      margin-bottom: 20px;
    }
  }
  .autoRenewCheckbox {
    margin-top: 30px;
  }
  .quote {
    width: 500px;
    padding: 30px;
    border: solid 1px $sheeo-lightGrey;
    border-radius: 5px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 150px;
    .quoteIcon img {
      width: 50px;
    }
    .quoteText {
      font-weight: bold;
      font-size: 20px;
      color: rgb(135, 135, 135);
    }
    .quoteName {
      font-weight: bold;
      font-size: 16px;
      color: $sheeo-teal;
      margin-top: 20px;
    }
  }
  .transactionFees {
    font-size: 12px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .landingContainer {
    .quote {
      width: 90%;
      margin: auto;
      margin-bottom: 50px;
    }

    h1 {
      font-size: 36px;
    }
    .description {
      width: 100%;
      padding: 0px 30px;
      font-size: 20px;
      line-height: 28px;
    }
    .regionContainer {
      width: 100%;
      padding: 0px 30px;
      text-align: center;
      margin-bottom: 10px !important;
      .regionButton {
        float: none;
        margin: auto;
        margin-bottom: 10px;
      }
    }
    .paymentOptions {
      width: 100%;
      padding: 0px 30px;
      margin-top: 30px;
      .card {
        margin-bottom: 20px;
      }
    }

    .logoMenuContainer {
      width: 200px !important;
      float: none !important;
      margin: auto !important;
      .menuContainer {
        display: none;
      }
    }
    .primary-cta {
      font-size: 15px;
    }
    .autoRenewCheckbox {
      font-size: 12px;
    }

    .eventImage {
      width: 100% !important;
      img {
        width: 100% !important;
      }
    }

    .emailEntry {
      width: 100%;
      padding: 0px 20px;
      .emailInput {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .eventName {
      padding: 0px 20px;
    }

    .eventDescription {
      width: 100%;
      padding: 0px 20px 0px 20px;
    }
  }
}
